<template>
    <div class="bg-color rounded-5 p-5">
        <div class="w-100 rounded-4">
            <div
                class="d-flex justify-content-between"
                style="background: rgb(241, 247, 255); border-radius: 27px 27px 0 0"
            >
                <h1 class="px-5 py-3 page-heading">Dashboard Build History</h1>
                <button class="text-button no-bg weight-600" style="margin-right: 10px; background: transparent">
                    <router-link class="text-link margin-0" :to="{ name: 'ClinicianDashboardBuilder' }">
                        <img src="@/assets/px/add.png" alt="" style="height: 20px" /> Create new dashboard
                    </router-link>
                </button>
            </div>
            <div class="w-100 bg-white" v-if="!template_list">
                <div class="d-grid justify-content-center align-items-center py-5" style="min-height: 400px">
                    <h4>Fetching information...</h4>
                </div>
            </div>
            <div
                v-else-if="template_list.length > 0"
                class="dashboard-list-table profile-listing-body pb-2"
                style="width: 82vw"
            >
                <b-table
                    id="my-table"
                    :items="template_list.slice().reverse()"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    bordered
                    responsive
                >
                    <template #cell(s_no)="row">
                        {{ (currentPage - 1) * perPage + row.index + 1 }}
                    </template>
                    <template #cell(dashboard_name)="row">
                        <b-tooltip
                            :target="`${row.index} dashboardName`"
                            :title="row.item.dashboardName"
                            triggers="hover"
                        />
                        <span :id="`${row.index} dashboardName`">{{ row.item.dashboardName }}</span>
                    </template>
                    <!-- <template #cell(status)="row">
                        <span
                            :style="{
                                color: row.item.status === 'draft' ? '#FF991F' : '#0c9',
                                'font-weight': '600',
                            }"
                            >{{ row.item.status }}</span
                        >
                    </template> -->
                    <template #cell(created_at)="row">
                        {{ formattedDate(row.item.created_at) }}
                    </template>
                    <template #cell(modified_at)="row">
                        {{ formattedDate(row.item.updated_at) }}
                    </template>
                    <template #cell(action)="row">
                        <div class="d-flex justify-content-center align-items-center" style="gap: 10px">
                            <ejs-tooltip
                                class="tooltipcontainer"
                                content="Edit"
                                target="#target"
                                cssClass="customtooltip"
                            >
                                <img
                                    src="@/assets/px/edit-icon.png"
                                    alt=""
                                    style="height: 20px"
                                    id="target"
                                    @click="editDashboard(row.item)"
                                />
                            </ejs-tooltip>
                            <ejs-tooltip
                                class="tooltipcontainer"
                                content="Delete"
                                target="#target"
                                cssClass="customtooltip"
                            >
                                <img
                                    src="@/assets/px/delete-icon.png"
                                    alt=""
                                    style="height: 20px"
                                    id="target"
                                    @click="showDeleteAlertPopUp(row.item.id)"
                                />
                            </ejs-tooltip>
                        </div>
                    </template>
                </b-table>
                <div class="b-table-pagination">
                    <div class="pagination-count-per-page">
                        <div class="pagination-number-per-page">
                            <div>
                                <span>Per page:</span>
                            </div>
                            <div>
                                <select style="" @change="changeCountPerPage" :value="perPage">
                                    <option v-for="item in perPageOptions" :key="item" :value="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <b-pagination
                        align="right"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        aria-controls="my-table"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                    ></b-pagination>
                </div>
            </div>
            <div class="w-100 bg-white" v-else>
                <div class="d-grid justify-content-center align-items-center py-5" style="min-height: 400px">
                    <h4>No items created</h4>
                </div>
            </div>
            <div class="profile-delete-modal">
                <DeleteDashboardModal
                    v-if="showDeleteAlert"
                    :open="showDeleteAlert"
                    @cancel="showDeleteAlert = false"
                    @handleDelete="handleDelete"
                />
            </div>
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>
    import DeleteDashboardModal from '../dashboard_builder/DeleteTemplateAlert';
    import Loading from '@/components/general/loading/loading.vue';
    import dayjs from '@/util/dayjs';
    import { clinicianDashboardBuilder } from '@/util/apiRequests';
    import { tryGetFilter } from '@/util/tryGetFilter';
    export default {
        name: 'ClinicianDashboardList',
        components: {
            DeleteDashboardModal,
            Loading,
        },
        data() {
            return {
                template_list: null,
                showDeleteAlert: false,
                indexTodelete: null,
                fields: [
                    { key: 's_no', label: 'S NO.', thStyle: { 'padding-left': '0 !important' }, class: 'table-col' },
                    { key: 'dashboard_name', label: 'Dashboard Name', class: 'table-col' },
                    { key: 'created_at', label: 'Created Date & Time', class: 'table-col' },
                    { key: 'created_by', label: 'Created By', class: 'table-col' },
                    { key: 'modified_at', label: 'Modified Date & Time', class: 'table-col' },
                    { key: 'updated_by', label: 'Modified By', class: 'table-col' },
                    { key: 'action', label: 'Action', class: 'action-sticky', stickyColumn: true, isRowHeader: true },
                ],

                // b-table properties
                storeKey: 'clinicianDashboardList',
                perPageOptions: [5, 10, 20, 50, 100],
                currentPage: 1,
                perPage: 10,
                totalxRows: null,
                dashboardDetails: null,
                loading: true,
            };
        },
        methods: {
            async getTemplateList() {
                try {
                    let list = await this.$api.get(clinicianDashboardBuilder.getTemplateList());
                    let data = list.data.dashboardList;
                    this.template_list = data;
                    this.totalRows = data.length;
                } catch (err) {
                    this.$toasted.error('Failed to fetched Dashboards');
                } finally {
                    this.loading = false;
                }
            },
            formattedDate(date) {
                return date ? dayjs(date).format('MM/DD/YYYY hh:mmA') : '';
            },
            showDeleteAlertPopUp(id) {
                this.showDeleteAlert = true;
                this.indexTodelete = id;
            },
            async handleDelete() {
                try {
                    this.showDeleteAlert = true;
                    let response = await this.$api.delete(clinicianDashboardBuilder.deleteTemplate(this.indexTodelete));
                    const { success, clientsUsingDashboard } = response.data;
                    this.showDeleteAlert = false;
                    if (!success) {
                        this.$toasted.error(
                            `Dashboard can't be deleted as it is assigned to ${clientsUsingDashboard} user(s)!`
                        );
                        return;
                    }
                    this.getTemplateList();
                    this.$toasted.success('Dashboard is deleted successfully !');
                } catch (e) {
                    const errorMessage = e ? e : 'Failed to delete member profile !';
                    this.$toasted.error(errorMessage);
                }

                this.showDeleteAlert = false;
            },
            changeCountPerPage({ target: { value } }) {
                this.perPage = Number(value);
            },
            savedPagination() {
                const previousPagination = tryGetFilter(this.$store, this.storeKey);
                this.currentPage = previousPagination.currentPage ?? 1;
                this.perPage = previousPagination.perPage ?? 10;
            },
            async editDashboard(value) {
                const { id } = value;
                this.$router.push({ name: 'ClinicianDashboardBuilder', query: { id } });
            },
        },
        async created() {
            this.savedPagination();
            await this.getTemplateList();
        },
        watch: {
            currentPage: function (val) {
                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: {
                        perPage: Number(this.perPage),
                        currentPage: Number(val),
                    },
                });
            },
            perPage: function (val) {
                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: {
                        currentPage: Number(this.currentPage),
                        perPage: Number(val),
                    },
                });
            },
        },
    };
</script>

<style scoped>
    .bg-color {
        background-color: rgba(255, 255, 255, 0.9);
    }

    .text-button a,
    .text-button a:hover,
    .text-button:hover {
        background-color: #5057c3;
        color: white;
        padding: 10px;
        border-radius: 10px;
    }
</style>

<style>
    .dashboard-list-table table tbody tr > td:first-child {
        padding-left: 2.5em !important;
    }

    .dashboard-list-table .b-table-sticky-column:last-child {
        right: 0;
        min-width: 100px;
    }

    .dashboard-list-table .action-sticky {
        min-width: 150px;
        box-shadow: -10px 0px 10px 1px #aaaaaa;
    }

    .dashboard-list-table .table-col {
        max-width: 150px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }
</style>
